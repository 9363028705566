<script setup lang="ts">
</script>
<template>
  <div
    class="tools"
    id="tools"
  >
    <div class="tools-content">
      <h2 class="center-title">ИНСТРУМЕНТЫ</h2>
      <div class="tools-content-plates">
        <div class="tools-content-plates-plate">
          <img
            class="tools-content-plates-plate-image"
            src="@/assets/images/svg/trafic.svg"
            alt="">
          <div class="tools-content-plates-plate-text">
            <p class="tools-content-plates-plate-text-title">Большие обьёмы <br>трафика</p>
            <p class="tools-content-plates-plate-text-description">
              JIGSAW ADS использует <br>
              эффективную комбинацию <br>
              инновационных систем <br>
              социального трафика
            </p>
          </div>
        </div>
        <div class="tools-content-plates-plate">
          <img
            class="tools-content-plates-plate-image platforms"
            src="@/assets/images/svg/platforms.svg"
            alt="">
          <div class="tools-content-plates-plate-text">
            <p class="tools-content-plates-plate-text-title">Платформы <br>размещения</p>
            <p class="tools-content-plates-plate-text-description">
              Позволяет привести <br>
              только целевую <br>
              аудиторию для наших <br>
              заказчиков.
            </p>
          </div>
        </div>
        <div class="tools-content-plates-plate">
          <img
            class="tools-content-plates-plate-image adblock"
            src="@/assets/images/svg/adblock.svg"
            alt="">
          <div class="tools-content-plates-plate-text">
            <p class="tools-content-plates-plate-text-title">Анти-Adblock</p>
            <p class="tools-content-plates-plate-text-description">
              Пользователи Adblock не <br>
              привыкли к рекламе, <br>
              поэтому ваши рекламные <br>
              места привлекают все их <br>
              внимание, что приводит к <br>
              более высокому CTR.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tools-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw-pc(1619px);
  margin-left: auto;
  margin-right: auto;
}
.tools {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw-pc(263px);
    &-plates {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: get-vw(70px);
      width: 85%;
      &-plate {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: get-vw(290px);
        &-image {
          height: get-vw-pc(256px);
          margin-right: get-vw(-10px);
          &.platforms {
            object-fit: contain;
            //width: get-vw(300px);
            height: get-vw-pc(256px);
            transform: scale(1.23);
          }
          &.adblock {
            object-fit: contain;
            width: get-vw(260px);
            height: get-vw-pc(256px);
          }
        }
        &-text {
          display: flex;
          flex-direction: column;
          color: #2D2D2D;
          margin-top: get-vw(35px);
          &-title {
            height: get-vw-pc(60px);
            font-size: get-vw(17px);
            line-height: get-vw(23px);
            font-weight: 600;
            text-align: center;
          }
          &-description {
            margin-top: get-vw(35px);
            font-size: get-vw(17px);
            line-height: get-vw(23px);
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
